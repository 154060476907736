import HttpRequest from './http-request'
import { getAuthToken } from '../utils/auth.utils'

class HomePagesProvider extends HttpRequest {
  getLastItem () {
    this.setHeader(getAuthToken())
    return this.get('/cms/home-pages')
  }

  updateItem (payload) {
    this.setHeader(getAuthToken())
    return this.post('/cms/home-pages', payload)
  }
}

export default HomePagesProvider
