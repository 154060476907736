<!-- eslint-disable vue/no-v-html -->
<template>
  <v-dialog
    v-model="modal"
    max-width="80vw">
    <v-card>
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            md="auto">
            เลือกประเภททัวร์
          </v-col>
          <v-spacer />
          <v-col
            cols="12"
            md="4">
            <SearchBox
              v-model="query.search"
              :disabled="loading"
              @search="getItems(true)" />
          </v-col>
          <v-col
            cols="12"
            md="auto">
            <tour-filters v-model="query" />
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text>
        <v-row align="center">
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="items"
              :loading="loading"
              hide-default-footer
              class="table-border">
              <template #[`item.imageUrl`]="{ item }">
                <div class="img-container mt-2 mb-1">
                  <img
                    v-if="item.imageUrl"
                    :src="item.imageUrl" />
                  <div
                    v-else
                    class="no-img rounded mb-2">
                    <v-icon size="60">
                      mdi-image-filter-hdr-outline
                    </v-icon>
                  </div>
                </div>
              </template>
              <template #[`item.description`]="{ item }">
                <div v-html="item.description" />
              </template>
              <template #[`item.actions`]="{ item }">
                <v-btn
                  color="primary"
                  outlined
                  depressed
                  :ripple="false"
                  style="width: fit-content; background-color: white;"
                  :disabled="loading || typeIds.includes(item.id)"
                  @click="onSelect(item)">
                  เลือกประเภท
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12">
            <div class="d-flex justify-end">
              <v-pagination
                v-model="query.page"
                :length="totalPages"
                :total-visible="7"
                circle
                color="primary" />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import SearchBox from '@/components/SearchBox.vue'
import TourTypesProvider from '@/resources/tour-types.provider'
import TourFilters from '@/components/TourFilters.vue'

const TourTypesService = new TourTypesProvider()

export default {
  components: {
    SearchBox,
    TourFilters
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    typeIds: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    loading: false,
    headers: [
      {
        text: 'รูปภาพ',
        value: 'imageUrl',
        class: 'table-header',
        sortable: false,
        width: '180'
      },
      {
        text: 'ประเภททัวร์',
        value: 'name',
        class: 'table-header',
        sortable: false,
        width: '180'
      },
      {
        text: 'รายละเอียด',
        value: 'description',
        class: 'table-header',
        cellClass: 'limit-text',
        sortable: false
      },
      {
        text: '',
        value: 'actions',
        class: 'table-header',
        sortable: false,
        align: 'right'
      }
    ],
    items: [],
    query: {
      page: 1,
      limit: 10,
      search: ''
    },
    totalPages: 1
  }),
  computed: {
    modal: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    'query.page': {
      handler () {
        this.getItems()
      },
      deep: true
    }
  },
  mounted () {
    this.getItems()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Snackbar/setSnackbar'
    }),
    async getItems (isClear = false) {
      try {
        this.loading = true

        if (isClear) {
          this.query.page = 1
          this.items = []
        }

        const { data } = await TourTypesService.getItems(this.query)

        this.items = data.results
        this.totalPages = data.pages
      } catch (error) {
        console.error('getItems', error)
        this.setSnackbar({
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    onSelect (item) {
      this.$emit('select', item)
    }
  }
}
</script>

<style scoped>
.table-border {
  border: 1px solid #E0E0E0;
  border-radius: 8px;
}
.img-container {
  width: 180px;
  height: auto;
}
.img-container img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}
.no-img {
  background-color: #E0E0E0;
  aspect-ratio: 4/3;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
