<!-- eslint-disable vue/no-v-html -->
<template>
  <v-row class="secondary--text">
    <v-col
      cols="12"
      md="4">
      <SearchBox
        v-model="query.search"
        :disabled="loading"
        @search="getItems()" />
    </v-col>
    <v-spacer />
    <v-col
      v-if="!readonly"
      cols="12"
      md="auto">
      <v-btn
        color="primary"
        outlined
        depressed
        block
        :ripple="false"
        style="width: fit-content; background-color: white;"
        @click="createItem()">
        <span>
          เพิ่มประเภท
        </span>
        <v-icon>
          mdi-plus
        </v-icon>
      </v-btn>
    </v-col>
    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        hide-default-footer
        class="table-border">
        <template #[`item.index`]="{ index }">
          {{ index + 1 }}
        </template>
        <template #[`item.imageUrl`]="{ item }">
          <div class="img-container mt-2 mb-1">
            <img
              v-if="item.imageUrl"
              :src="item.imageUrl" />
            <div
              v-else
              class="no-img rounded mb-2">
              <v-icon size="60">
                mdi-image-filter-hdr-outline
              </v-icon>
            </div>
          </div>
        </template>
        <template #[`item.description`]="{ item }">
          <div v-html="item.description" />
        </template>
        <template
          v-if="!readonly"
          #[`item.actions`]="{ item }">
          <v-btn
            color="error"
            icon
            :disabled="loading"
            @click="confirmDialogToggle(item)">
            <v-icon>
              mdi-trash-can-outline
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-col>
    <type-list-modal
      v-model="dialog"
      :type-ids="formData"
      @select="onSelect($event)" />
  </v-row>
</template>

<script>
import { mapActions } from 'vuex'
import SearchBox from '@/components/SearchBox.vue'
import TourTypesProvider from '@/resources/tour-types.provider'
import TypeListModal from './TypeListModal.vue'

const TourTypesService = new TourTypesProvider()

export default {
  components: {
    SearchBox,
    TypeListModal
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loading: false,
    items: [],
    query: {
      page: 1,
      limit: 999,
      search: '',
      typeIds: []
    },
    dialog: false
  }),
  computed: {
    headers () {
      const tmpHeaders = [
        {
          text: 'ลำดับ',
          value: 'index',
          class: 'table-header',
          align: 'center',
          sortable: false,
          width: '100'
        },
        {
          text: 'รูปภาพ',
          value: 'imageUrl',
          class: 'table-header',
          sortable: false,
          width: '180'
        },
        {
          text: 'ประเภททัวร์',
          value: 'name',
          class: 'table-header',
          sortable: false,
          width: '180'
        },
        {
          text: 'รายละเอียด',
          value: 'description',
          class: 'table-header',
          cellClass: 'limit-text',
          sortable: false
        }
      ]

      if (!this.readonly) {
        tmpHeaders.push({
          text: '',
          value: 'actions',
          class: 'table-header',
          sortable: false,
          align: 'right'
        })
      }

      return tmpHeaders
    },
    formData: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  mounted () {
    if (this.formData.length) {
      this.query.typeIds = [...this.formData]
      this.getItems()
    }
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Snackbar/setSnackbar'
    }),
    async getItems () {
      if (!this.formData.length) {
        this.items = []
        return
      }

      try {
        this.loading = true

        const { data } = await TourTypesService.getItems(this.query)

        this.items = data.results
      } catch (error) {
        console.error('getItems', error)
        this.setSnackbar({
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    onRemove (id) {
      const index = this.formData.findIndex((i) => i === id)

      this.formData.splice(index, 1)
      this.query.typeIds = [...this.formData]
      this.getItems()
    },
    createItem () {
      this.dialog = true
    },
    confirmDialogToggle (item) {
      this.$store.dispatch('ConfirmDialog/setDialog', {
        active: true,
        title: 'แจ้งเตือน',
        text: `คุณยืนยันที่จะ "ลบ" ${item.name} ใช่หรือไม่?`,
        onConfirm: () => this.onRemove(item.name)
      })
    },
    onSelect (item) {
      this.dialog = false
      this.formData.push(item.id)
      this.query.typeIds = [...this.formData]
      this.getItems()
    }
  }
}
</script>

<style scoped>
.table-border {
  border: 1px solid #E0E0E0;
  border-radius: 8px;
}
.img-container {
  width: 180px;
  height: auto;
}
.img-container img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}
.no-img {
  background-color: #E0E0E0;
  aspect-ratio: 4/3;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
