<template>
  <v-container fluid>
    <v-form
      ref="form"
      @submit.prevent="onSubmit()">
      <v-row>
        <v-col
          cols="12"
          md="auto"
          class="px-0">
          <v-btn
            color="primary"
            outlined
            depressed
            :ripple="false"
            style="width: fit-content; background-color: white;"
            @click="onBack()">
            <v-icon>
              mdi-chevron-left
            </v-icon>
            <span>
              ย้อนกลับ
            </span>
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          class="px-0 pb-0">
          <v-expansion-panels
            class="mb-4"
            style="gap: 1rem;">
            <v-expansion-panel class="rounded-lg">
              <v-expansion-panel-header>
                <h3 class="primary--text">
                  แบนเนอร์ (หลัก)
                </h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <image-uploader
                  v-model="images.homeBanner"
                  name="homeBanner"
                  :max-file="1"
                  square
                  :disabled="loading" />
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="rounded-lg">
              <v-expansion-panel-header>
                <h3 class="primary--text">
                  แบนเนอร์ (บน)
                </h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <image-uploader
                  v-model="images.topAdsBanner"
                  name="topAdsBanner"
                  :max-file="1"
                  square
                  :disabled="loading" />
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="rounded-lg">
              <v-expansion-panel-header>
                <h3 class="primary--text">
                  ทัวร์ยอดนิยม
                </h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <popular-tours v-model="formData.popularTours" />
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="rounded-lg">
              <v-expansion-panel-header>
                <h3 class="primary--text">
                  ทริปส่วนตัวเฉพาะคุณ
                </h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12">
                    <h3>
                      แบนเนอร์
                    </h3>
                    <v-divider class="mt-2 mb-4" />
                    <image-uploader
                      v-model="images.privateTourBanner"
                      name="privateTourBanner"
                      :max-file="1"
                      square
                      :disabled="loading" />
                  </v-col>
                  <v-col cols="12">
                    <h3>
                      ทริปส่วนตัวเฉพาะคุณ
                    </h3>
                    <v-divider class="mt-2 mb-4" />
                    <private-tours v-model="formData.privateTours" />
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="rounded-lg">
              <v-expansion-panel-header>
                <h3 class="primary--text">
                  แบนเนอร์ (ล่าง)
                </h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <image-uploader
                  v-model="images.bottomAdsBanner"
                  name="bottomAdsBanner"
                  :max-file="1"
                  square
                  :disabled="loading" />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="6"
          md="3">
          <v-btn
            color="primary"
            outlined
            block
            :ripple="false"
            depressed
            :disabled="loading"
            style="background-color: white;"
            @click="onBack()">
            ยกเลิก
          </v-btn>
        </v-col>
        <v-col
          cols="6"
          md="3">
          <v-btn
            color="primary"
            block
            :ripple="false"
            depressed
            :loading="loading"
            @click="onSubmit()">
            บันทึก
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import ImageUploader from '@/components/ImageUploader.vue'
import HomePagesProvider from '@/resources/home-pages.provider'
import UploaderProvider from '@/resources/uploader.provider'
import PopularTours from '../../components/PopularTours.vue'
import PrivateTours from '../../components/PrivateTours.vue'

const HomePagesService = new HomePagesProvider()
const UploaderService = new UploaderProvider()

export default {
  components: {
    ImageUploader,
    PopularTours,
    PrivateTours
  },
  data: () => ({
    loading: false,
    images: {
      homeBanner: [],
      topAdsBanner: [],
      privateTourBanner: [],
      bottomAdsBanner: []
    },
    formData: {
      homeBanner: '',
      topAdsBanner: '',
      popularTours: [],
      privateTourBanner: '',
      privateTours: [],
      bottomAdsBanner: ''
    }
  }),
  mounted () {
    this.getItem()
  },
  methods: {
    onBack () {
      this.$router.push({ name: 'Contents' })
    },
    async getItem () {
      try {
        this.loading = true

        const { data } = await HomePagesService.getLastItem()

        this.formData = data ? { ...data } : this.formData
        this.images = {
          homeBanner: data?.homeBanner ? [{
            file: null,
            preview: data.homeBanner,
            mediaUrl: data.homeBanner
          }] : [],
          topAdsBanner: data?.topAdsBanner ? [{
            file: null,
            preview: data.topAdsBanner,
            mediaUrl: data.topAdsBanner
          }] : [],
          privateTourBanner: data?.privateTourBanner ? [{
            file: null,
            preview: data.privateTourBanner,
            mediaUrl: data.privateTourBanner
          }] : [],
          bottomAdsBanner: data?.bottomAdsBanner ? [{
            file: null,
            preview: data.bottomAdsBanner,
            mediaUrl: data.bottomAdsBanner
          }] : []
        }
      } catch (error) {
        console.error('getItem', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    async onSubmit () {
      try {
        this.loading = true
        const valid = await this.$refs['form'].validate()

        if (!valid) {
          return
        }

        const tmpFormData = { ...this.formData }
        const keys = Object.keys(this.images)

        for await (const key of keys) {
          const image = this.images[key][0]
          tmpFormData[key] = image?.mediaUrl || ''

          if (image?.file) {
            const { data } = await UploaderService.uploadFile(image.file)

            tmpFormData[key] = data?.publicUrl
          }
        }

        await HomePagesService.updateItem(tmpFormData)

        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: 'แก้ไข ข้อมูลเสร็จสิ้น',
          type: 'success',
          timeout: 2000
        })

        this.getItem()
      } catch (error) {
        console.error('onSubmit', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.form-container {
  background-color: white;
  border: 1px solid #E0E0E0;
}
</style>
