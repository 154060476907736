import HttpRequest from './http-request'
import { getAuthToken } from '../utils/auth.utils'

class ToursProvider extends HttpRequest {
  getItems (query) {
    this.setHeader(getAuthToken())
    return this.get('/tours', query)
  }

  getItemByName (query) {
    this.setHeader(getAuthToken())
    return this.get('/tours/by-name', query)
  }

  getItemById (id) {
    this.setHeader(getAuthToken())
    return this.get(`/tours/${id}`)
  }

  create (payload) {
    this.setHeader(getAuthToken())
    return this.post('/tours', payload)
  }

  updateItemById (payload) {
    this.setHeader(getAuthToken())
    return this.patch(`/tours/${payload.id}`, payload)
  }

  updateItemStatusById (id) {
    this.setHeader(getAuthToken())
    return this.put(`/tours/${id}/status`)
  }

  deleteItemById (id) {
    this.setHeader(getAuthToken())
    return this.delete(`/tours/${id}`)
  }
}

export default ToursProvider
